import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import './index.css';
import Login from './pages/login';
import SmsCode from './pages/smscode';
import Map from './pages/map';
import reportWebVitals from './reportWebVitals';

const baseURL = process.env.PUBLIC_URL;

const router = createBrowserRouter([
  {
    path: baseURL + "/",
    element: <Login />,
  },
  {
    path: baseURL + "/smscode",
    element: <SmsCode />,
  },
  {
    path: baseURL + "/map",
    element: <Map />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
    {/* <BrowserRouter>
      <Routes>
        <Route index element={<Login />} />
        <Route path="smscode" element={<SmsCode />} />
        <Route path="map" element={<Map />} />
      </Routes>
    </BrowserRouter> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
