import { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from "../assets/ct-white-transparent.png"
import '../styles/smscode.css';
import secureLocalStorage from "react-secure-storage";

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
// cargo-tracker-web/src/assets/ct-black.png

function SmsCode() {
  const navigate = useNavigate();
  const { state } = useLocation()
  const inputRef = useRef()
  const [code, setCode] = useState("")
  const [cells, setCells] = useState([" ", " ", " ", " ", " ", " "])

  function handleChange(e) {
    let text = e.target.value
    let textArr = text.split("");
    let localCells = [" ", " ", " ", " ", " "]
    for (let i = 0; i < textArr.length; i++) {
      localCells[i] = textArr[i]
    }
    setCells(localCells);
    setCode(text.replace(/[^0-9]/g, ''));
  }

  useEffect(() => {
    document.title = 'Kirjaudu sisään | Paluukuorma';
  }, []);

  useEffect(() => {


    if (code.length === 6) {
      let registrationtoken = secureLocalStorage.getItem("registrationToken")
      fetch('https://entry.paluukuorma.com/paluukuorma/authentication/registrationcode', {
        // fetch('https://paluukuorma.astrovar.fi/paluukuorma/authentication/registrationcode', {
        method: 'POST',
        headers: {
          'Accept': '*/*',
          'Content-Type': 'text/plain',
        },
        credentials: 'include',
        body: JSON.stringify({ "registrationtoken": registrationtoken, "smscode": code })
      })
        .then(res => {
          // console.log(JSON.stringify(res), res.status)
          return res.text()
        })
        .then(data => {

          // console.log(data)
          let realData = JSON.parse(data);

          if (!realData.error) {
            // console.log(realData, typeof realData.deviceid, typeof realData.userid)
            // console.log("savedInfo", realData)
            secureLocalStorage.setItem("userInfo", JSON.stringify(realData))
            navigate("/map")
          }
          else if (realData.error && realData.error.errorcode === 33) {
            toast.error('Väärä koodi. Yritä uudelleen.', {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          }
          else if (realData.error && realData.error.errorcode === 42) {
            toast.error('Enimmäisyritysmäärää on ylitetty, kohta sinua siirretään alkuun.', {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            setTimeout(() => { navigate("/") }, 1000)
          }
          else if (realData.error && realData.error.errorcode === 43) {
            toast.error('Koodi on vanhentunut, kohta sinua siirretään alkuun.', {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            setTimeout(() => { navigate("/") }, 1000)
          }
          else {
            toast.error('Tapahtui tuntematon virhe. Yritä uudelleen!', {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          }

          setCode("")
          setCells([" ", " ", " ", " ", " ", " "])
        })
    }

  })

  return (
    <div className="sms-page">
      <div className="white-bg" />
      <div className="sms-container">
        <div className="sms-overlay" />
        <div className="contents">
          <div className="sms-logo-conatiner"><img className='sms-logo' src={logo} alt="Logo" /></div>
          <div className="sms-title">LÄHETIMME SINULLE KOODIN!</div>
          <div className="sms-subtitle">NÄPPÄILE SE TÄHÄN</div>

          <div className="sms-code">
            <input ref={inputRef} className="sms-code-input" maxLength={6} value={code} onChange={handleChange} />
            <div className="sms-code-cells">
              <div className="sms-code-cell">{cells[0]}</div>
              <div className="sms-code-cell">{cells[1]}</div>
              <div className="sms-code-cell">{cells[2]}</div>
              <div className="sms-code-cell">{cells[3]}</div>
              <div className="sms-code-cell">{cells[4]}</div>
              <div className="sms-code-cell">{cells[5]}</div>
            </div>
          </div>

          <div className="sms-phonenumber">Koodi lähetettiin numeroon {"\n"} {state ? state.phonenumber : ""}</div>
        </div>
      </div>

      <ToastContainer
        position="top-center"
        autoClose={3000}
        limit={1}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="dark"
      />
    </div>
  )
};

export default SmsCode;