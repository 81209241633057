import * as React from "react"

const ContactSvg2 = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53.84 53.84" style={{maxWidth: props.maxWidth}}>
    <g>
      <path
        d="M25.35 0h3.16c.69.08 1.38.15 2.08.25a26.23 26.23 0 0 1 16 8.34 26.21 26.21 0 0 1 7 21.93 25.64 25.64 0 0 1-8.33 16.09 26.25 26.25 0 0 1-23.37 6.75A25.93 25.93 0 0 1 7 45 26.14 26.14 0 0 1 .41 31.51c-.17-1-.27-2-.41-3v-3.16c0-.19.07-.37.1-.56.13-1 .2-1.95.39-2.91A26.45 26.45 0 0 1 8.84 7 26.29 26.29 0 0 1 22.34.41c1-.17 2.01-.27 3.01-.41ZM12.74 19.8a10.43 10.43 0 0 0 1.08 4.2 29.57 29.57 0 0 0 6.26 9.07 28.22 28.22 0 0 0 12.17 7.57 7.13 7.13 0 0 0 5.21-.43 11.79 11.79 0 0 0 3.12-2.55 1.2 1.2 0 0 0 0-2C39 34.15 37.47 32.58 35.91 31a1.21 1.21 0 0 0-2 0c-.86.86-1.69 1.73-2.55 2.58a2.45 2.45 0 0 1-3.42.27 35.61 35.61 0 0 1-7.85-7.7 2.46 2.46 0 0 1 .23-3.53c.89-.85 1.78-1.72 2.68-2.62a1.18 1.18 0 0 0 0-1.92q-2.37-2.38-4.75-4.76a1.19 1.19 0 0 0-1.92 0c-.47.45-.92.91-1.38 1.38a7.29 7.29 0 0 0-2.21 5.1Z"
        style={{
          fill: "#fff",
        }}
      />
    </g>
  </svg>
)

export default ContactSvg2
