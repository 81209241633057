import * as React from "react"

const ArrowDown = (props) => (
  <svg className="arrow-down" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.08 26.87" {...props}>
    <g>
      <path
        style={{
          fill: "transparent",
          stroke: "#fff",
          strokeMiterlimit: 10,
          strokeWidth: 4,
        }}
        d="M46.67 1.41 24.04 24.04 1.41 1.41"
      />
    </g>
  </svg>
)

export default ArrowDown
