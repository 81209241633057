// import { useRef, useState, useEffect } from 'react';
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import '../styles/map.css';
import secureLocalStorage from "react-secure-storage";
import { GoogleMap, Marker, LoadScript, InfoWindow } from '@react-google-maps/api';
// import { Wrapper } from "@googlemaps/react-wrapper";
// import { Loader } from "@googlemaps/js-api-loader"

function Map() {

  const [truckLocations, setTruckLocations] = useState({ truckLocations: [] })
  const [truckInfo, setTruckInfo] = useState([])
  const [cargoLocations, setCargoLocations] = useState({ cargoLocations: [] })
  const [cargoInfo, setCargoInfo] = useState([])
  const [trucks, setTrucks] = useState({ trucks: [] })
  const [activeMarker, setActiveMarker] = useState(null);
  const [center, setCenter] = useState({ lat: 65, lng: 27 });

  const carTypes = {
    "sidecovered": "Sivusta lastattava, katettu",
    "backcovered": "Perästä lastattava, katettu",
    "openplatform": "Avolava",
    "van": "Pakettiauto",
    "crane": "Nosturi",
    "roofcovered": "Katosta lastattava, katettu",
    "openplatformcrane": "Avolava + nosturi",
    "openplatformramp": "Avolava + ajorampit",
    "unknown": "Tuntematon",
  }

  let vars = [truckLocations, trucks, Link, setTruckLocations, setTruckInfo, setTrucks, setCenter]
  vars.forEach(() => { })

  async function login() {
    let res = secureLocalStorage.getItem("userInfo")
    // console.log(res)
    // return fetch('https://paluukuorma.astrovar.fi/paluukuorma/authentication/login', {
    return fetch('https://entry.paluukuorma.com/v2/authentication/login', {
      method: 'POST',
      headers: {
        'Accept': '*/*',
        'Content-Type': 'text/plain',
      },
      credentials: 'include',
      body: res
    })
      .then(res => res.text())
      .then(data => data)
  }

  useEffect(() => {
    document.title = 'CargoTracker | Paluukuorma';
  }, []);

  useEffect(() => {
    let dataInterval = setInterval(() => {

      let loc = [];
      let cargoIds = [];
      let truckIds = [];
      let dataJson = {};
      [setCargoLocations, setCargoInfo, login, loc, cargoIds, truckIds, dataJson].map( () => "")
      const intervalFunc = async () => {
        
        try {
          // if ((dataJson && dataJson.error) || Object.keys(dataJson).length === 0) {
          //   console.log("login1")
          //   dataJson.loggedIn = "yes"
          //   await login()
          // }
          await login()
          await fetch('https://entry.paluukuorma.com/v2/application/cargo/getcargos', {
            method: 'GET',
            headers: {
              'Accept': '*/*',
              'Content-Type': 'text/plain',
            },
            credentials: 'include',
          })
            .then(response => response.text())
            .then((res) => {
              let data = JSON.parse(res)
              // console.log(data)
              dataJson = data;
              if (data && data.error && data.error.errorcode === 10011) {
                login().then(() => "")
              }

              if (dataJson.cargos) {
                cargoIds = dataJson.cargos.map(item => item.cargoid)
              }

              return fetch('https://entry.paluukuorma.com/v2/application/cargo/getcargolocations', {
                method: 'POST',
                headers: {
                  'Accept': '*/*',
                  'Content-Type': 'text/plain',
                },
                credentials: 'include',
                body: JSON.stringify({ "cargoid": cargoIds ? [...cargoIds] : [] })
              })
                .then(response => response.text())
                .then(res => {
                  let data = JSON.parse(res);

                  if (data && data.error && data.error.errorcode === 10011) {
                    // console.log("login3")
                    login()
                  }

                  loc = JSON.parse(res)

                  return fetch('https://entry.paluukuorma.com/v2/application/cargo/getcargoinfo', {
                    method: 'POST',
                    headers: {
                      'Accept': '*/*',
                      'Content-Type': 'text/plain',
                    },
                    credentials: 'include',
                    body: JSON.stringify({ "cargoid": cargoIds ? [...cargoIds] : [] })
                  })
                    .then(response => response.text())
                    .then(res => {
                      let data = JSON.parse(res)

                      if (data && data.error && data.error.errorcode === 10011) {
                        // console.log("login4")
                        login()
                      }

                      setCargoInfo(data && data.cargocompanyinfo && data.cargocompanyinfo.length > 0 ? [...data.cargocompanyinfo] : [...cargoInfo])
                      setCargoLocations(loc)
                      return
                    })
                })
            })

          await fetch('https://entry.paluukuorma.com/v2/application/truck/gettrucks', {
            method: 'GET',
            headers: {
              'Accept': '*/*',
              'Content-Type': 'text/plain',
            },
            credentials: 'include',
          })
            .then(response => {
              return response.text()
            })
            .then(res => {
              let data = JSON.parse(res)
              //console.log(data)
              dataJson = data;
              if (data && data.error && data.error.errorcode === 10011) {
                //console.log("login2t")
                login()
              }

              if(dataJson.trucks) {
                truckIds = dataJson.trucks.map(item => item.truckid)
              }

              return fetch('https://entry.paluukuorma.com/v2/application/truck/gettrucklocations', {
                method: 'POST',
                headers: {
                  'Accept': '*/*',
                  'Content-Type': 'text/plain',
                },
                credentials: 'include',
                body: JSON.stringify({ truckid: [...truckIds] })
              })
                .then(response => response.text())
                .then(res => {

                  let data = JSON.parse(res);

                  if (data && data.error && data.error.errorcode === 10011) {
                    //console.log("login3t")
                    login()
                    return
                  }

                  loc = JSON.parse(res)

                  return fetch('https://entry.paluukuorma.com/v2/application/truck/gettruckinfo', {
                    method: 'POST',
                    headers: {
                      'Accept': '*/*',
                      'Content-Type': 'text/plain',
                    },
                    credentials: 'include',
                    body: JSON.stringify({ truckid: [...truckIds] })
                  })
                    .then(response => response.text())
                    .then(res => {
                      let data = JSON.parse(res)
                      if (data && data.error && data.error.errorcode === 10011) {
                        //console.log("login4t")
                        login()
                        return
                      }
                      setTruckInfo(data && data.truckcompanyinfo && data.truckcompanyinfo.length > 0 ? [...data.truckcompanyinfo] : [...truckInfo])
                      setTruckLocations(loc)
                    })
                })
          })
        }
        catch (e) {
          // console.log("error cargo", e)
        }
      }
      intervalFunc()
    }, 10 * 1000)

    return () => clearInterval(dataInterval);
  }, [cargoInfo, truckInfo])

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const renderMarkersTruck = () => {
    // console.log("truckLocations.truckLocations", truckLocations.truckLocations)
    if (!truckInfo || truckInfo.length <= 0) {
      // console.log("truckInfo empty")
      return
    }
    return truckLocations.truckLocations.map((item, i) => {

      return (
        <Marker
          key={item.truckid}
          style={{ width: "5px", height: "5px" }}
          icon={svgMarkerRed}
          // onLoad={marker => console.log(marker)}
          position={{ lat: item.latitude, lng: item.longitude }}
          onClick={() => handleActiveMarker(item.truckid)}
        >
          {activeMarker === item.truckid ? (
            <InfoWindow onCloseClick={() => setActiveMarker(null)}>
              <div>
                <div>{truckInfo[i].companyinfo.name}</div>
                <div>{truckInfo[i].truckinfo.availablemass} ton / {truckInfo[i].truckinfo.availablevolume} lvm</div>
                <div>{carTypes[truckInfo[i].truckinfo.type] || "tuntematon"}</div>
                <div>Määränpää: {truckInfo[i].truckinfo.destination}</div>
                <div>Kontaktoi: {truckInfo[i].companyinfo.phone}</div>

              </div>
            </InfoWindow>
          ) : <div />}
        </Marker>
      )
    })
  }
  const renderMarkersCargo = () => {
    // console.log("truckLocations.truckLocations", truckLocations.truckLocations)
    if (!cargoInfo || cargoInfo.length <= 0) {
      // console.log("truckInfo empty")
      return
    }
    return cargoLocations.cargoLocations.map((item, i) => {

      return (
        <Marker
          key={item.truckid}
          style={{ width: "5px", height: "5px" }}
          icon={svgMarkerBlue}
          // onLoad={marker => console.log(marker)}
          position={{ lat: item.latitude, lng: item.longitude }}
          onClick={() => handleActiveMarker(item.truckid)}
        >
          {activeMarker === item.truckid ? (
            <InfoWindow onCloseClick={() => setActiveMarker(null)}>
              <div>
                <div>{cargoInfo[i].companyinfo.name}</div>
                <div>{cargoInfo[i].cargoinfo.mass} ton / {cargoInfo[i].cargoinfo.volume} lvm</div>
                <div>{carTypes[cargoInfo[i].cargoinfo.type] || "tuntematon"}</div>
                <div>Lastauspaikkakunta: {cargoInfo[i].cargoinfo.source}</div>
                <div>Purkupaikkakunta: {cargoInfo[i].cargoinfo.destination}</div>
                <div>Kontaktoi: {cargoInfo[i].companyinfo.phone}</div>

              </div>
            </InfoWindow>
          ) : <div />}
        </Marker>
      )
    })
  }

  return (

    // <Wrapper apiKey={"AIzaSyD3VUHMm3jZ4NViE8bvG0NX3C1bsBjv9Lc"}>
    //   <MyMapComponent center={center} zoom={5} />
    // </Wrapper>

    <LoadScript
      googleMapsApiKey="AIzaSyD3VUHMm3jZ4NViE8bvG0NX3C1bsBjv9Lc"
    >
      <GoogleMap
        mapContainerStyle={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
        // onLoad={(map) => map.setCenter(undefined)}
        // onLoad={(map) => console.log(map)}
        onTilesLoaded={() => setCenter(null)}
        options={{ disableDefaultUI: true, zoomControl: true }}
        // defaultCenter={center}
        center={center}
        zoom={5}

      >
        { /* Child components, such as markers, info windows, etc. */}
        {truckInfo && truckLocations && renderMarkersTruck()}
        {cargoInfo && cargoLocations && renderMarkersCargo()}
      </GoogleMap>
    </LoadScript>

  )
};

// function MyMapComponent({ center, zoom }) {
//   const ref = useRef();

const svgMarkerRed = {
  path: "M 100, 100 m 75, 0 a 75,75 0 1,0 -150,0 a 75,75 0 1,0  150,0",
  fillColor: "red",
  fillOpacity: 1,
  strokeWeight: 0,
  rotation: 0,
  scale: 0.1,
};
const svgMarkerBlue = {
  path: "M 100, 100 m 75, 0 a 75,75 0 1,0 -150,0 a 75,75 0 1,0  150,0",
  fillColor: "blue",
  fillOpacity: 1,
  strokeWeight: 0,
  rotation: 0,
  scale: 0.1,
};

//   useEffect(() => {
//     const map = new window.google.maps.Map(ref.current, {
//       center,
//       zoom,
//       disableDefaultUI: true,
//     });
//     new window.google.maps.Marker({
//       position: center,
//       map,
//       title: "Hello World!",
//       // icon: require("../assets/svg/circle.svg").default
//       icon: svgMarker
//     });
//   });

//   return <div ref={ref} id="map" className='map' />;
// }

export default Map;