import { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from "../assets/ct-black.png"
import '../styles/login.css';
import ArrowDown from '../assets/svg/arrowDown.js'
import ContactSvg1 from '../assets/svg/contactSvg1.js'
import ContactSvg2 from '../assets/svg/contactSvg2.js'
import secureLocalStorage from "react-secure-storage";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// cargo-tracker-web/src/assets/ct-black.png

function Login() {
    // const [phoneNum, setPhoneNum] = useState(0);
    let navigate = useNavigate();
    const inputRef = useRef()
    const userInfo = secureLocalStorage.getItem("userInfo");
    // console.log(userInfo)


    useEffect(() => {
        document.title = 'Kirjaudu sisään | Paluukuorma';
    }, []);

    useEffect(() => {
        if (!userInfo) return
        fetch('https://entry.paluukuorma.com/paluukuorma/authentication/login', {
            method: 'POST',
            headers: {
                'Accept': '*/*',
                'Content-Type': 'text/plain',
            },
            credentials: 'include',
            body: userInfo
        })
            .then(res => {
                return res.text()
            })
            .then(data => {
                const realData = JSON.parse(data);
                if (!realData.error) {
                    // saveToSecureStore("registrationToken", realData.registrationtoken)
                    // navigation.navigate("SmsCode", { phone: phone })
                    // setPhone("")
                    setTimeout(() => {
                        navigate("/map", { state: { phonenumber: inputRef.current.value } });
                    }, 10);
                }
                else {
                    toast.error('Tapahtui tuntematon virhe. Yritä uudelleen!', {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                }
            })
            .catch(err => {
                console.error("err", err)

            });
    }, [userInfo, navigate])

    function login(inputRef) {

            fetch('https://entry.paluukuorma.com/paluukuorma/authentication/registrationstart', {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'text/plain',
                },
                credentials: 'include',
                body: JSON.stringify({ "phonenumber": inputRef.current.value })
            })
                .then(res => {
                    return res.text()
                })
                .then(data => {
                    const realData = JSON.parse(data);
                    if (!realData.error && realData.registrationtoken) {
                        // saveToSecureStore("registrationToken", realData.registrationtoken)
                        // navigation.navigate("SmsCode", { phone: phone })
                        // setPhone("")
                        setTimeout(() => {
                            secureLocalStorage.setItem("registrationToken", realData.registrationtoken)
                            navigate("/smscode", { state: { phonenumber: inputRef.current.value } });
                        }, 10);
                    }
                    else if (realData.error && realData.error.errorcode === 31) {
                        toast.error('Virheellinen puhelinnumero! Laita se +358... muodossa ja yritä uudelleen!', {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                        });
                    } else {
                        toast.error('Tapahtui tuntematon virhe. Yritä uudelleen!', {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                        });
                    }
                })
                .catch(err => {
                    console.error("err", err)

                });
        }

    return (
        <div className="login-page">
            <div className="login-page-image-wrap">
                <img src={logo} className="login-page-image" alt="Cargo Tracker" />
            </div>
            <div className="feature-text">Syötä puhelinnumerosi kirjautuaksesi Cargo Trackeriin</div>

            <div className="black-bg">
                <div className="arrow-container">
                    <ArrowDown />
                </div>

                <div className="phone-input-wrap">
                    <input ref={inputRef} className="phone-input" type="tel" placeholder="puhelinnumerosi" />
                </div>

                <button className="button" onClick={() => {
                    login(inputRef)
                }}>
                    Kirjaudu
                    <div className="button-triangle-container">
                        <div className="button-triangle-bg"></div>
                        <div className="button-triangle"></div>
                    </div>
                </button>

                <div className="features">
                    <div className="features-container">
                        <div className="features-title">Eikö sinulla ole vielä tiliä?</div>

                        <div className="features-items">
                            <div className="features-text">Soita tai lähetä meille viestiä tästä, niin tehdään sinullekin tili!</div>
                            <div className="features-icons">
                                <ContactSvg1 />
                                <ContactSvg2 />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer
                position="top-center"
                autoClose={3000}
                limit={1}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover={false}
                theme="dark"
            />
        </div>
    )
};

export default Login;