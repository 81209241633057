import * as React from "react"
/* SVGR has dropped some elements not supported by react-native-svg: title */

const ContactSvg1 = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53.84 53.84" style={{maxWidth: props.maxWidth}}>
    <g>
      <path
        d="M26.92 0a26.92 26.92 0 1 0 26.92 26.93A26.92 26.92 0 0 0 26.92 0ZM14.29 16.76h25.26a2 2 0 0 1 .78.15L26.92 30.32l-4-4-9.38-9.38a2 2 0 0 1 .75-.18ZM12.21 35V18.84a2 2 0 0 1 .15-.78l8.81 8.81-8.85 8.84a2.33 2.33 0 0 1-.11-.71Zm27.34 2.08H14.29a2 2 0 0 1-.86-.19L22.32 28l4.6 4.6 4.6-4.6 8.89 8.88a2 2 0 0 1-.86.21ZM41.64 35a2.06 2.06 0 0 1-.12.7l-8.85-8.84 8.81-8.81a2 2 0 0 1 .16.78Z"
        style={{
          fill: "#fff",
        }}
      />
    </g>
  </svg>
)

export default ContactSvg1
